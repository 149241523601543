@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  height: 47px;
  @media (--viewportMedium) {
    height: auto;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  flex: 1;

  @media (--viewportMedium) {
    flex: unset;
  }

  &:first-child {
    margin-left: 0;
  }
}
