@import '../../styles/customMediaQueries.css';

.topbar {
  z-index: 1;
}

.mobileTopbar {
  @media (--viewportMedium) {
    display: none;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.wizard {
  flex-grow: 1;
}

.footer {
  @media (--viewportMedium) {
    display: none;
  }
}
