@import '../../../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
  max-width: 800px;
}

.title {
  /* Font */
  color: var(--marketplaceColor);
  text-transform: capitalize;

  /* Margins */
  margin-top: 0;
  margin-bottom: 32px;

  max-width: 200px;
  @media (--viewportMedium) {
    max-width: 100%;
    margin-bottom: 48px;
  }
}
