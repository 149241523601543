.root {
  fill: none;
  transition: var(--transitionStyleButton);

  & path {
    stroke: var(--colorGrey700);
  }

  &:hover {
    & path {
      stroke: var(--marketplaceColor);
    }
  }
}
