@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 24px 24px 48px;
  @media (--viewportMedium) {
    padding: 48px 36px;
  }
}

.content {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* Style */
  border: 1px solid var(--marketplaceColor);

  /* Size */
  flex: 1;
  height: 100%;
  padding: 24px;

  @media (--viewportMedium) {
    padding: 48px;
  }
}

.contentWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (--viewportLarge) {
    margin-top: -48px;
  }
}

.heading {
  /* Font */
  color: var(--marketplaceColor);
  text-align: center;

  /* Width */
  max-width: 740px;

  margin-top: 0;
  margin-bottom: 32px;
}

.number {
  composes: h2 from global;
  composes: heading;
  text-decoration: underline;

  margin-top: 0;
  margin-bottom: 32px;
}

.description {
  /* Font */
  color: var(--marketplaceColor);
  text-align: center;

  margin-top: 0;
  margin-bottom: 0;

  max-width: 450px;
}
