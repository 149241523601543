:root {
  --heightDesktop: 36px;
}

.inputRoot {
  height: var(--heightDesktop);
}

.searchIcon {
  display: none;
}

.input {
  composes: p from global;
  flex-grow: 1;
  /* Font */
  height: var(--heightDesktop);
  line-height: unset;
  border: none;
  padding: 0 18px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  /* Style */
  background-color: var(--colorWhite);
  border: 1px solid var(--marketplaceColor);
  border-radius: 9999px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inputFocused {
  border-bottom-left-radius: 0;
}

.predictions {
  position: absolute;
  width: 100%;
  top: var(--heightDesktop);
  left: 0;
  background-color: var(--marketplaceColor);
  box-shadow: var(--boxShadowPopup);
  border-bottom-left-radius: var(--borderRadiusMedium);
  border-bottom-right-radius: var(--borderRadiusMedium);
  border-top-right-radius: var(--borderRadiusMedium);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  min-width: 300px;
  max-width: 434px;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}
