@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  color: var(--colorFail);

  /* Margin */
  margin-top: 0;
  margin-bottom: 32px;
}

.formMargins {
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.fieldsWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fieldContainer {
  composes: formMargins;
}

.fieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (--viewportMedium) {
    align-items: flex-start;
    flex-direction: row;
  }
}

.fieldGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}

.field {
  flex: 1;
}

.fieldLabel {
  display: block;
  margin-bottom: 24px;
}

.submitButton {
  /* Font */
  color: var(--colorWhite);

  /* Style */
  border: 0;
  background-color: var(--marketplaceColor);
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  @media (--viewportMedium) {
    /* Height */
    min-height: 72px;
    height: 72px;

    /* Layout */
    flex: 1;
  }

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    background-color: var(--marketplaceColorDark);
    outline: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}

.experienceLabel {
  /* Font */
  font-size: 14px;
  font-family: var(--fontFamily);
  opacity: 0.5;

  /* Layout */
  display: block;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
  margin-left: 12px;
  cursor: pointer;
  bottom: 2px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: grey;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 5px;
  left: 24px;
  font-size: 12px;
  font-family: 'basis-grotesque-regular-pro';
  text-transform: initial;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.fieldLabelwithToolTip {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

.checkboxFields {
  /* margin-top: 24px; */

  & ul li label {
    margin-bottom: 4px;
  }

  @media (--viewportMedium) {
    & ul {
      column-gap: 12px;
    }
  }
}
