@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.projectList {
  margin: 0;
  padding: 0;

  list-style: none;
}

.projectListItem {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 18px;

  /* Size */
  padding: 15px 0;

  /* Style */
  border-bottom: 1px solid var(--marketplaceColor);

  @media (--viewportLarge) {
    display: grid;
    grid-template-columns: 25% 35% 10% auto;
  }
}

.projectListItemRow {
  display: flex;
  align-items: center;

  &:nth-child(3) {
    display: none;
  }
  @media (--viewportLarge) {
    &:nth-child(3) {
      display: flex;
    }
  }
}

.projectListItemFirstRow {
  justify-content: space-between;

  @media (--viewportLarge) {
    justify-content: flex-start;
  }
}

.projectListItemLastRow {
  align-items: center;

  @media (--viewportLarge) {
    justify-content: flex-end;
  }
}

.projectListItemRowHeading {
  composes: h4 from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    font-family: var(--fontFamilySecondary);
    font-weight: var(--fontWeightLight);
    font-size: 24px;
    line-height: 99%;

    letter-spacing: -0.03em;
    text-transform: capitalize;
    white-space: nowrap;
  }
}

.projectListItemRowSubHeading {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  color: var(--marketplaceColor);
  font-size: 10px;
  line-height: 12px;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;

  letter-spacing: -0.05em;
  text-transform: uppercase;

  /* Margin */
  margin-right: 14px;

  @media (--viewportLarge) {
    font-size: 14px;
    line-height: 17px;
  }
}

.projectListItemRowSubHeadingMobile {
  font-size: 14px;
  line-height: 17px;

  @media (--viewportLarge) {
    display: none;
  }
}

.projectListItemRowSubHeadingWithSpacing {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-left: 72px;
  }
}

.projectListItemRowSmallText {
  /* Font */
  font-size: 13px;
  line-height: 130%;
  color: var(--marketplaceColor);
  text-transform: capitalize;

  /* Margin */
  margin-right: 12px;

  @media (--viewportLarge) {
    text-align: center;
    margin-right: 24px;

    /* Size */
    max-width: 100px;
  }
}

.projectListItemImages {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 8px;

  margin-right: 12px;
}

.projectListItemImage {
  /* Size & Style */
  height: 28px;
  width: 38px;
  object-fit: cover;

  /* Style */
  border: 1px solid var(--marketplaceColor);
}

.addProjectWrapper {
}

.addProjectsListWrapper {
  padding: 15px 0;
  border-bottom: 1px solid var(--marketplaceColor);
}

.addNewProjectHeading,
.editProjectHeading {
  /* Font */
  font-size: 32px;
  color: var(--marketplaceColor);

  margin-top: 32px;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    margin-top: 12px;
  }
}

.editProjectHeading {
  /* Margin */
  margin-bottom: 6px;
}

.editProjectText {
  /* Font */
  font-size: 14px;
  color: var(--marketplaceColor);

  /* Margin */
  margin-top: 0;
  margin-bottom: 24px;
}

.addProjectButton {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  /* Font */
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }
}

.plusIconWrapper,
.editIconWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  /* Style */
  border: 1px solid var(--marketplaceColor);
  border-radius: 50%;

  /* Size */
  height: 20px;
  width: 20px;
}

.plusIcon,
.editIcon {
  & line {
    stroke-width: 1;
  }
}

.editIcon {
  stroke: transparent;
  height: 11px;
}

.editProjectModalButton {
  max-width: 150px;
  margin-top: 48px;
}
