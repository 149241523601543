@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
}

.input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  /* Display the "check" when checked */
  &:checked + label .checked {
    display: inline;
  }

  /* Highlight the borders if the checkbox is hovered, focused or checked */
  &:hover + label .notChecked,
  &:hover + label .required,
  &:focus + label .notChecked,
  &:focus + label .required,
  &:checked + label .required {
    stroke: var(--marketplaceColor);
  }

  &:checked + label .notChecked {
    stroke: none;
  }

  /* Hightlight the text on checked, hover and focus */
  &:focus + label .labelText,
  &:hover + label .labelText,
  &:checked + label .labelText {
    color: var(--marketplaceColor);
  }
}

.label {
  display: flex;
  align-items: center;
}

.radioButtonWrapper {
  margin-right: 12px;
  align-self: center;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.checkedStyle {
  stroke: var(--marketplaceColor);
}

.checked {
  display: none;
}

.notChecked {
  stroke: var(--marketplaceColor);
  &:hover {
    stroke: pink;
  }
}

.required {
  stroke: var(--colorAttention);
  &:hover {
    stroke: pink;
  }
}

.labelText {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--marketplaceColor);
  cursor: pointer;

  /* Position */
  position: relative;
  top: 1.5px;
}

.smallText {
  composes: marketplaceSmallFontStyles from global;
  font-style: italic;
  text-transform: none;
}
