@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  color: var(--colorFail);

  /* Margin */
  margin-top: 0;
  margin-bottom: 32px;
}

.fieldContainer {
  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}

.fieldLabel {
  display: block;
  composes: label from global;

  /* Margin */
  margin-top: 0;
  margin-bottom: 0;
}

.fieldText {
  /* Font */
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColor);
  text-transform: initial;

  /* Margin */
  margin-top: 4px;
  margin-bottom: 12px;
}

.uploadLabel {
  /* Layout */
  display: block;
  width: fit-content;
  margin-top: 32px;
  margin-bottom: 0px;
}

.uploadInput {
  display: none;
}

.uploadZone {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  /* Style */
  border: 1px solid var(--marketplaceColor);
  border-radius: 56px;

  /* Size */
  width: fit-content;
  padding: 6px 18px;

  /* Effect */
  cursor: pointer;
}

.uploadZoneIconWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  border: 1px solid var(--marketplaceColor);
  border-radius: 50%;

  /* Size */
  height: 20px;
  width: 20px;
}

.uploadZoneIcon {
  stroke: var(--marketplaceColor) !important;

  & line {
    stroke-width: 1;
  }
}

.uploadZoneLabel {
  /* Font */
  font-size: 16px;
  line-height: 100%;
}

.imageFromFileWrapper {
  position: relative;
  width: fit-content;
}

.imageFromFile {
  /* Dimensions */
  width: var(--CreateCompanyPage_avatarSize);
  height: var(--CreateCompanyPage_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--colorGrey100); /* Loading BG color */
  border-radius: calc(var(--CreateCompanyPage_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--CreateCompanyPage_avatarSizeDesktop);
    height: var(--CreateCompanyPage_avatarSizeDesktop);
    border-radius: calc(var(--CreateCompanyPage_avatarSizeDesktop) / 2);
  }
}

.imageFromFileLabelWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Position */
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);

  /* Style */
  background: var(--colorWhite);
  border: 1px dotted var(--marketplaceColor);
  border-radius: 56px;

  /* Size */
  width: fit-content;
  padding: 6px 12px;

  /* Effect */
  cursor: pointer;

  @media (--viewportMedium) {
    padding: 6px 18px;
  }
}

.imageFromFileLabel {
  /* Font */
  font-size: 16px;
  line-height: 100%;
}

.imagesField {
  margin-top: 24px;

  @media (--viewportMedium) {
    max-width: 600px;
    margin-top: 32px;
  }
}

.submitButton {
  /* Font */
  color: var(--colorWhite);

  /* Style */
  border: 0;
  background-color: var(--marketplaceColor);
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  @media (--viewportMedium) {
    /* Height */
    min-height: 72px;
    height: 72px;

    /* Layout */
    flex: 1;
  }

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    background-color: var(--marketplaceColorDark);
    outline: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}

.footer {
  margin-top: 48px;
}


.newsLetter{
  margin-top: 20px;
}

.newsLetterfieldText {
  /* Font */
  font-size: 18px;
  line-height: 18px;
  color: var(--marketplaceColor);
  text-transform: initial;

  /* Margin */
  margin-top: 4px;
  margin-bottom: 12px;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.fieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  margin-top: 20px;

  &:nth-child(2) {
    flex-direction: row;
  }

  @media (--viewportMedium) {
    align-items: flex-start;
    flex-direction: row;
  }

  /* Margins */
  composes: formMargins;
  &:last-child {
    margin-bottom: 0;
  }

  & .field {
    flex: 1;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.passwordContainer{
  margin-top: 20px;
}

.customCheckbox{
    height: 32px;
    margin-top: -1px;
    margin-right: 8px;
    align-self: baseline;
  
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  
    @media (--viewportMedium) {
      height: 28px;
    }
}