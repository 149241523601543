@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.header {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Mask border */
  border-bottom: 1px solid var(--marketplaceColor);
}

.content {
  /* Layout */
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* Size */
  margin-top: 48px;
  padding: 0 24px 24px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.loginButton,
.logoutButton {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-weight: var(--fontWeightRegular);
  color: var(--colorWhite);
  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Padding */
  padding: 12px 24px;
}

.navigationLinks {
  composes: content;

  /* Remove spacing */
  margin-top: 0;
  padding: 0;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--colorWhite);
  text-transform: capitalize;

  /* Layout */
  margin-top: 0;
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.legalLinks {
  composes: content;

  /* Remove spacing */
  margin-top: 96px;
  padding: 0;
}

.legalLink {
  /* Font */
  color: var(--colorWhite);
  text-transform: capitalize;

  /* Margin */
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}

.closeButton {
  display: flex;
  align-items: center;

  /* Padding */
  padding: 12px 24px;
  width: 66px;
}

.closeButtonIcon {
  fill: var(--colorWhite);

  & path {
    stroke: var(--colorWhite);
  }
}

.currentPageLink {
  color: var(--colorWhite);
}

.currentPage {
  /* black left border */
  border-left: 5px solid var(--colorWhite);
  margin-left: -24px;
  padding-left: 19px;
}

.spacer {
  width: 100%;
  height: 124px;
}
