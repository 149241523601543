@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.formMargins {
  margin-bottom: 24px;
}

.fields {
  position: relative;
}

.field {
  composes: formMargins;

  &:last-child {
    margin-top: 40px;
    margin-bottom: 0;
  }
}

.fieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &:nth-child(2) {
    flex-direction: row;
  }

  @media (--viewportMedium) {
    align-items: flex-start;
    flex-direction: row;
  }

  /* Margins */
  composes: formMargins;
  &:last-child {
    margin-bottom: 0;
  }

  & .field {
    flex: 1;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.submitButton {
  margin-top: 48px;
}
