@import '../../../styles/customMediaQueries.css';

.panel {
  position: relative;
  flex-grow: 1;

  @media (--viewportMedium) {
    /* Style */
    border: 1px solid var(--marketplaceColor);

    /* Padding */
    padding: calc(72px + 55px) 72px calc(72px + 72px);
  }

  @media (--viewportLarge) {
    padding: calc(72px + 55px) 120px calc(96px + 72px);
  }
}
