@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;

  /* Style */
  border: 1px solid var(--marketplaceColor);

  @media (--viewportLarge) {
    grid-template-columns: 1fr auto 1fr;
  }
}

.authenticatedRoot {
  flex: 1;

  @media (--viewportLarge) {
    grid-template-columns: 1fr;
  }
}

.box {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.talentBox {
  grid-column-start: 1;
  grid-row-start: 1;

  @media (--viewportMedium) {
    grid-column-start: unset;
    grid-row-start: unset;
  }
}

.boxContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  /* Size */
  width: 100%;
  padding: 24px;
}

.boxHeading,
.boxText {
  color: var(--marketplaceColor);
  text-align: center;
}

.boxHeading {
  /* Font */
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 26px;

  /* Max-width */
  max-width: 500px;
}

.boxText {
  margin-top: 0;
  margin-bottom: 0;

  /* Max-width */
  max-width: 250px;

  @media (--viewportMedium) {
    max-width: 460px;
  }
}

.separator {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.separator {
  height: 100%;
  width: 1px;

  /* Style */
  background-color: var(--marketplaceColor);
}

.boxButton {
  composes: buttonSecondary from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Font */
  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);

  /* Size */
  min-height: 82px;
  height: 82px;
  padding: 0;

  /* Style */
  border: 0;
  outline: 1px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    min-height: 110px;
    height: 110px;

    &:hover {
      /* Font */
      color: var(--colorWhite);

      /* Style */
      background-color: var(--marketplaceColor);
      outline: 1px solid var(--marketplaceColor);
      box-shadow: none;
    }
  }
}

.mainContent {
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1023px) {
    display: block;
  }
}

.mainTitle {
  /* color: #B6030A; */
  font-family: 'Berlingske Serif';
  font-size: 54px;
  font-style: normal;
  font-weight: bold;
  line-height: 109%; /* 58.86px */
  letter-spacing: -1.08px;
  width: 80%;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 32px;
    letter-spacing: -0.64px;
  }
}

.mainDescription {
  /* color: #B6030A; */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 109%; /* 19.62px */
  letter-spacing: -0.36px;
  padding: 30px 0px;
  width: 80%;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 14px;
    letter-spacing: -0.28px;
  }
}

.mainDescription2 {
  /* color: #B6030A; */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 109%; /* 19.62px */
  letter-spacing: -0.36px;
  padding-bottom: 97px;
}

.leftContent {
  background-image: url('../../../assets/map.png');
  background-size: contain;
  background-position: center; /* Centers the image within the element */
  width: 55%;
  margin: 52px 0px 0px;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0px;
    background-image: none;
  }
}

.iconDesktopContent {
  display: flex;
  text-align: center;
  gap: 10px;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.svgContent {
  padding: 24px 32px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 4px 15.9px 0px rgba(186, 186, 186, 0.25);

  @media (max-width: 600px) {
    padding: 12px 16px;
  }
}

.formContent {
  padding: 32px;
  border: 1px solid #eee;
  background: #fff;
  box-shadow: -5px 4px 16.6px 0px rgba(185, 185, 185, 0.25);
  width: 35%;
  margin: 0px 30px;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 30px 0px;
    padding: 20px;
  }
}

.formContentTitle {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 109%; /* 21.8px */
  letter-spacing: -0.4px;
}

.formContentDescription {
  margin-top: 8px;
  color: #7a7a7a;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 109%; /* 17.44px */
  letter-spacing: -0.32px;
}

.form {
  flex-grow: 1;
  max-width: 800px;
  margin: 20px 0px;
}

.mobileMap {
  @media (max-width: 1023px) {
    background-image: url('../../../assets/map.png');
    background-size: contain;
    background-position: center; /* Centers the image within the element */
  }
}

/* Aside Content */
.asideContent {
  /* Position */
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
  width: 100vw;

  /* Style */
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    width: 100%;
  }

  @media (--viewportLarge) {
    position: unset;
    top: unset;
    background-color: unset;
    width: auto;
  }
}

/* Aside Navigation */
.asideNav {
  @media (--viewportLarge) {
    /* Position */
    position: fixed;
    top: calc(50% - var(--topbarHeight));
    left: 36px;
  }
}

.asideNavList {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;

  /* Scroll */
  overflow-x: auto;

  margin: 0;
  padding: 0;

  @media (--viewportMedium) {
    overflow-x: hidden;
  }

  @media (--viewportLarge) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.asideNavListItem {
  /* Font */
  white-space: nowrap;
  text-transform: uppercase;

  /* Margin */
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;

  /* Style */
  cursor: pointer;
  opacity: 0.4;

  /* Effect */
  transition: var(--transitionStyleButton);

  &:first-child {
    margin-left: 0;
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }

  @media (--viewportMedium) {
    &:first-child {
      padding-left: 36px;
    }

    &:last-child {
      padding-right: 36px;
    }
  }

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 6px;
    margin-left: 0;

    &:hover {
      opacity: 0.6;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.asideNavListActiveItem {
  opacity: 1;

  &:hover {
    opacity: 1;
  }
}

.sections {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  /* Padding */
  /* padding: 0 24px; */

  @media (--viewportMedium) {
    /* padding: 0 36px; */
  }

  @media (--viewportLarge) {
    /* padding: 0 0px 0 calc(var(--asideWidth) + 12px); */
  }

  @media (min-width: 1250px) {
    /* padding: 0 calc(var(--asideWidth) + 72px); */
  }
}

/* .newLandingPage{
  margin-bottom: 32px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }

  @media (--viewportLarge) {
    margin-bottom: 52px;
  }
} */
