@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;

  /* Style */
  background-color: var(--colorWhite);
  border-bottom: 1px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    border-top: 1px solid var(--marketplaceColor);
  }
}

.darkSkin {
  background-color: var(--colorGrey700);
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.tabContent {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-weight: var(--fontWeightRegular);
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;

  /* Padding */
  padding: 12px;
  border-right: 1px solid var(--marketplaceColor);

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    font-size: 12px;
    line-height: 14px;

    padding: 16px 36px;
    width: auto;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.selectedTabContent {
  background-color: var(--marketplaceColor);
  color: var(--colorWhite);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--colorGrey300);

  &:hover {
    color: var(--colorWhite);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--colorWhite);
  color: var(--colorWhite);
}

.disabledDarkSkin {
  color: var(--colorBlack);
  opacity: 0.3;
}
