@import '../../styles/customMediaQueries.css';

:root {
  --connectButtonHeight: 73px;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--colorFail);
  margin: 24px;
}

.rootForImage {
  width: 100%;

  @media (--viewportMedium) {
    height: 450px;
    width: 100%;
    object-fit: cover;

    border: 1px solid var(--marketplaceColor);
  }
}

.rootForImageForTalent {
  /* Layout */
  height: 100%;
  width: 100%;
  max-height: 400px;
  object-fit: cover;

  /* Style */
  outline: 1px solid var(--marketplaceColor);

  @media (--viewportLarge) {
    min-width: 500px;
    max-height: unset;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapper {
  /* margin-bottom: 48px; */

  @media (--viewportMedium) {
    margin-top: 12px;
    /* margin-bottom: 72px; */
    padding: 0 36px;
  }

  @media (--viewportLarge) {
    /* margin-bottom: 96px; */
  }
}

.contentBox {
  @media (--viewportMedium) {
    /* Style */
    border-left: 1px solid var(--marketplaceColor);
    border-right: 1px solid var(--marketplaceColor);
    border-bottom: 1px solid var(--marketplaceColor);
  }
}

.sectionTitle {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  /* Position */
  /* position: sticky;
  top: calc(var(--topbarHeight) + 43px);
  z-index: 9; */

  /* Padding */
  padding: 12px 24px;

  /* Style */
  border-bottom: 1px solid var(--marketplaceColor);
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    /* top: var(--topbarHeightDesktop); */
    border-top: 1px solid var(--marketplaceColor);
    border-left: 1px solid var(--marketplaceColor);
    border-right: 1px solid var(--marketplaceColor);
  }
}

.sectionTitle h1 {
  /* Limit text to 2 lines */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.titleIndustryIcon {
  /* Size */
  height: 28px;
  width: auto;

  /* Layout */
  flex-shrink: 0;

  @media (--viewportMedium) {
    height: 35px;
  }
}

.contentFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  /* flex-direction: column-reverse; */
  gap: 24px;

  @media (--viewportMedium) {
    padding: 24px 24px 72px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    /* flex-direction: row;
    align-items: flex-start; */
    /* justify-content: space-between;
    gap: 124px; */

    /* Padding */
    padding: 32px 32px 72px;
  }
}

.contentFlexForTalent {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (--viewportMedium) {
    padding: 24px 24px 72px;
  }

  @media (--viewportLarge) {
    flex-direction: row;

    /* new */
    padding: 24px 24px 96px 72px;
  }
}

.imageColumn {
  @media (--viewportLarge) {
    margin-top: calc(72px + 48px);
  }

  @media (min-width: 1560px) {
    flex-basis: 800px;
    flex-shrink: 0;
  }

  @media (--viewportXLarge) {
    flex-basis: 1000px;
  }
}

.imageColumnForTalent {
  @media (--viewportLarge) {
    flex-shrink: 0;
    max-width: 500px;
    max-height: calc(100vh - (calc(var(--topbarHeightDesktop) + 102px + 12px)));
  }
}

.sectionDetailsWrapper {
  position: relative;
  flex: 1;
  display: flex;
  /* align-items: center; */
}

.sectionDetailsTalentWrapper {
  display: flex;
  /* align-items: center; */
  flex: 1;
  @media (--viewportLarge) {
    padding: 48px;
    flex: 1;
  }
}

.sectionDetails {
  padding: 0 24px;

  @media (--viewportMedium) {
    width: 100%;
    padding: 0;

    & .detailValue {
      text-align: left;
    }
  }

  @media (--viewportLarge) {
    min-width: 35vw;
    max-width: 550px;
    width: 100%;
  }

  @media (min-width: 1560px) {
    min-width: 50vw;
    /* max-width: 100%; */
    width: 100%;
  }
}

.sectionDetailsTalent {
  /* padding: 24px 24px 0;

  @media (--viewportLarge) {
    padding: 0;
  } */
  padding: 24px;

  @media (--viewportMedium) {
    width: 100%;
    padding: 0;

    & .detailValue {
      text-align: left;
    }
  }

  @media (--viewportLarge) {
    min-width: 35vw;
    max-width: 550px;
    width: 100%;
  }

  @media (min-width: 1560px) {
    min-width: 50vw;
    /* max-width: 100%; */
    width: 100%;
  }
}

.sectionDetailsTalent .detailsRow {
  &:last-child {
    border-bottom: 0;
  }

  @media (--viewportLarge) {
    &:last-child {
      border-bottom: 1px solid var(--marketplaceColor);
    }
  }
}

.sectionDetailsHeading {
  color: var(--marketplaceColor);
  text-transform: capitalize;

  margin-top: 6px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.sectionDetailsSubheading {
  font-family: var(--fontFamilyPrimary);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
}

.details {
  margin: 20px 0px;
}

.detailsRow {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  padding: 20px 0;

  /* Style */
  border-bottom: 1px solid var(--marketplaceColor);

  &:last-child {
    border-bottom: 0;
  }

  &:first-child {
    border-top: 1px solid var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    &:last-child {
      border-bottom: 1px solid var(--marketplaceColor);
    }
  }
}

.detailLabel {
  composes: h2 from global;

  /* Override */
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
}

.detailValue {
  font-size: 14px;
  line-height: 130%;
  color: var(--marketplaceColor);
  text-align: right;

  margin: 0;
}

.instagramDetailsValue {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin-left: auto;

  &:hover {
    opacity: 0.7;
  }
}

.instagramDetailsIcon {
  & path {
    stroke: var(--marketplaceColor);
  }
}

.sectionDescription {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Width */
  width: 100%;
  padding: 0 24px;
  margin: 48px auto;

  @media (--viewportMedium) {
    margin: 0 auto 0;
    padding-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 900px;
    padding: 0 0 72px;
    margin: 0 auto;
  }
}

.description {
  /* Font */
  font-family: var(--fontFamilySecondary);
  font-weight: var(--fontWeightLight);
  font-size: 24px;
  line-height: 99%;
  letter-spacing: -0.03em;
  text-transform: capitalize;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 38px;
  }

  margin-top: 0;
  margin-bottom: 0;
}

.sectionConnect,
.sectionConnectMobile,
.sectionConnectTalent,
.sectionConnectTalentMobile {
  /* Layout */
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  /* Style */
  border-top: 1px solid var(--marketplaceColor);
}

.sectionConnect,
.sectionConnectTalent {
  @media (--viewportMedium) {
    /* Style */
    border-top: 0;
    border-bottom: 1px solid var(--marketplaceColor);
    border-left: 1px solid var(--marketplaceColor);
    border-right: 1px solid var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    /* Position */
    position: sticky;
    bottom: 0;
    z-index: 8;

    /* Layout */
    flex-direction: row;
    height: var(--connectButtonHeight);

    /* Style */
    background-color: var(--colorWhite);
    border-top: 1px solid var(--marketplaceColor);
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }
}

.sectionConnect {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
  }
}

.sectionConnectMobile {
  display: flex;

  /* Position */
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--colorWhite);
  z-index: 100;
  @media (--viewportMedium) {
    display: none;
  }
}

.sectionConnectTalent {
  display: none;

  @media (--viewportLarge) {
    display: flex;
  }
}

.sectionConnectTalentMobile {
  display: flex;

  /* Position */
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--colorWhite);

  @media (--viewportLarge) {
    display: none;
  }
}

.connectTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;

  /* Padding */
  padding: 12px 24px;

  @media (--viewportMedium) {
    width: 100%;
    gap: 28px;
  }

  @media (--viewportLarge) {
    justify-content: unset;
    width: unset;
    padding: 0 0 0 24px;
  }
}

.connectTitle {
  /* Font */
  font-size: 20px;
  font-weight: var(--fontWeightLight);
  line-height: 99%;
  letter-spacing: -0.03em;
  text-transform: capitalize;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 24px;
  }

  @media (--viewportLarge) {
    font-size: 38px;
  }
}

.connectSubHeading {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;

  @media (--viewportLarge) {
    align-items: flex-start;
  }
}

.connectSubHeadingLocation {
  font-family: var(--fontFamilyPrimary);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.connectSubHeadingIndustry {
  font-family: var(--fontFamilySecondary);
  font-weight: var(--fontWeightLight);
  font-size: 20px;
  line-height: 99%;
  letter-spacing: -0.03em;
  text-transform: capitalize;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.connectButton {
  /* Size */
  min-height: var(--connectButtonHeight);
  height: var(--connectButtonHeight);
  width: 100%;

  @media (--viewportLarge) {
    width: 350px;
  }
}

.sectionGallery {
  flex: 1;
  display: flex;
  flex-direction: column;

  /* margin-top: 48px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 72px;
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-top: 96px;
  } */
}

.galleryTitle {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  text-transform: uppercase;
  font-size: 32px;

  /* Padding & Margin */
  padding: 12px 24px;
  margin-top: 0;
  margin-bottom: 0;

  /* Style */
  /* border-top: 1px solid var(--marketplaceColor);
  border-left: 1px solid var(--marketplaceColor);
  border-right: 1px solid var(--marketplaceColor); */
  border: 1px solid var(--marketplaceColor);
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    top: var(--topbarHeightDesktop);
  }
}

.footer {
  @media (--viewportMedium) {
    display: none;
  }
}

.actionBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 24px;
  color: var(--colorGrey100);
  background-color: var(--colorGreen);
  border-bottom: 1px solid var(--marketplaceColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  font-size: 24px;
  letter-spacing: -0.03em;
  margin-top: 0;
  margin-bottom: 0;
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  font-size: 24px;
  letter-spacing: -0.03em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  width: 100%;
}

.editListingLink {
  composes: h5 from global;
  font-size: 24px;
  flex-shrink: 0;
  margin: 0;
  color: var(--colorGrey100);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.editIcon {
  margin: -4px 8px 0 0;
}

.sectionDocuments {
  margin-top: 48px;
  /* padding: 0 24px; */

  /* @media (--viewportMedium) {
    margin-top: 72px;
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-top: 96px;
  } */
}

.documentsTitle {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  text-transform: capitalize;

  /* Padding & Margin */
  padding: 12px 24px;
  margin-top: 0;
  margin-bottom: 0;

  /* Style */
  background-color: var(--colorWhite);
  border-top: 1px solid var(--marketplaceColor);
  border-left: 1px solid var(--marketplaceColor);
  border-right: 1px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    top: var(--topbarHeightDesktop);
  }
}

.documentsContent {
  padding: 24px;

  /* Style */
  border: 1px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 24px 48px;
  }
}

.documents {
  margin: 0;
  padding: 0;

  list-style: initial;
  list-style-position: inside;
}

.inquirySubmitButtonWrapper {
  margin-top: 32px;
}

.iconBack {
  padding: 10px 0px;
  background-color: white;

  @media (max-width: 767px) {
    padding: 10px;
  }
}

.sectionTitleContent {
  /* Position */
  position: sticky;
  top: calc(var(--topbarHeight) + 43px);
  z-index: 9;

  /* Padding */
  /* padding: 12px 24px; */

  @media (--viewportMedium) {
    top: var(--topbarHeightDesktop);
  }
}

.sectionTitleImage {
  height: 88px;
  width: 88px;
  object-fit: cover;
}

.sectionTitleLeft {
  display: flex;
  gap: 20px;
  align-items: center;
}

.sectionTitleDescription {
  font-family: Inter;
  text-transform: uppercase;
  font-weight: 400;
  display: flex;
  gap: 8px;
  font-size: medium;
}

.specialTitle {
  display: flex;
  gap: 20px;
  text-transform: capitalize;
  margin: 28px 0px;
}

.listingPageContent {
  gap: 20px;
  justify-content: center;
  padding: 20px 0px;

  @media (min-width: 950px) {
    display: flex;
  }
}

.listingPageDisplayContent {
  border: 1px solid var(--marketplaceColor);

  @media (min-width: 767px) {
    display: flex;
  }
}

.sectionPortfolioImage {
  height: 100%;
  width: 100%;

  object-fit: cover;

  @media (min-width: 767px) {
    max-width: 263px;
    max-height: 394px;
  }
}

.listingPageDisplayLeft {
  padding: 40px;
  background-color: #f5f5f5;

  @media (min-width: 767px) {
    max-width: 320px;
    height: 100vh;
  }
}

.listingPageDisplayRight {
  padding: 40px;
  flex: 1;

  @media (min-width: 875px) {
    min-width: 530px;
    max-width: 600px;
  }
}

.sectionPortfolioTitleContent {
  /* Layout */
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @media (min-width: 767px) {
    display: flex;
  }
}

.sectionPortfolioMobileTitleContent {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding-top: 24px;

  @media (min-width: 767px) {
    display: none;
  }
}

.sectionPortfolioTitleDescription {
  font-family: Inter;
  text-transform: uppercase;
  font-weight: 400;
  display: none;
  gap: 8px;
  font-size: medium;

  @media (min-width: 767px) {
    display: flex;
  }
}

.sectionPortfolioMobileTitleDescription {
  font-family: Inter;
  text-transform: uppercase;
  font-weight: 400;
  display: flex;
  gap: 8px;
  font-size: medium;
  padding-bottom: 24px;

  @media (min-width: 767px) {
    display: none;
  }
}

.sectionPortfolioTitle {
  font-family: Inter;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1.08px;
  text-align: start;
  text-transform: uppercase;
}

.sectionPortfolioButtonContent {
  display: flex;
  gap: 8px;
  padding: 20px 0px;
}

.sectionPortfolioTopic {
  color: #9c9c9c;
  padding-bottom: 8px;
  text-transform: uppercase;
}

.sectionPortfolioDivider {
  height: 1px;
  align-self: stretch;
  background: #b6030a;
  margin: 20px 0px;
}

.sectionPortfolioTopicTitle {
  font-weight: bold;
  line-height: normal;
  font-size: 18px;
}

.sectionPortfolioTopicDescription {
  line-height: normal;
}

.sectionPortfolioFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0px;
  gap: 12px;

  @media (min-width: 520px) {
    flex-direction: row;
  }
}

.listingCardDetails {
  padding: 0px 8px;
  border-radius: 50px;
  border: 1px solid #b70201;
  text-transform: capitalize;
}

.listCardMiddle {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.sectionProfileLeftTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  letter-spacing: -0.32px;
}

.sectionProfileLeftDescription {
  color: #9c9c9c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  text-transform: lowercase;
}

.leftStyleContent {
  /* padding: 0px 0px 20px; */
}

.sectionPortfolioDividerNormal {
  height: 1px;
  align-self: stretch;
  background: #d4d4d4;
  margin: 20px 0px;
}
