@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  background-color: var(--colorGreen);
  padding: 16px 24px;

  @media (--viewportMedium) {
    text-align: center;
  }
}

.text {
  margin: 0;
  color: #fff;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 20px;
}

.button {
  background: var(--marketplaceColorDark);
  margin: 0;
  padding: 8px 16px;
  border-radius: var(--borderRadiusMedium);
  font-size: 14px;
  color: #fff;
  text-align: center;
  border: 0;

  &:hover {
    text-decoration: none;
    background: var(--marketplaceColor);
  }
}
