@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.label {
  padding-bottom: 8px;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 0;
  max-width: 250px;
}
