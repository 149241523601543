@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;

  margin-bottom: 24px;

  /* Font */
  width: 100%;
  height: auto;

  @media (--viewportMedium) {
    /* Layout */
    flex-direction: row;

    /* Position */
    position: absolute;
    top: 0;
    left: 0;

    /* Size */
    padding: 18px 24px;
    margin-bottom: 0;

    /* Style */
    border-bottom: 1px solid var(--marketplaceColor);
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.heading,
.order {
  /* Position */
  position: relative;

  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-weight: var(--fontWeightRegular);
  font-size: 12px;
  line-height: 14px;

  letter-spacing: -0.05em;
  text-transform: uppercase;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 19px;
  }
}

.heading {
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    color: var(--colorWhite);
  }
}

.order {
  color: var(--marketplaceColor);
}

.filler {
  /* Style */
  border: 1px solid var(--marketplaceColor);

  height: 40px;
  width: 100%;

  @media (--viewportMedium) {
    position: absolute;
    top: 0;
    left: 0;

    border: 0;
    height: 100%;
  }
}

.fillerBar {
  /* Style */
  background-color: var(--marketplaceColor);

  height: 40px;
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;
    min-width: calc(192px + 48px);
  }
}
