@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 24px 24px 72px;

  @media (--viewportMedium) {
    padding: 36px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    padding: 48px 36px;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--colorGrey100);
  overflow-x: scroll;

  @media (--viewportLarge) {
    padding: 72px 0 82px 36px;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--colorGrey50);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 72px 0 82px calc((100% - 1056px) / 2);
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

.customField {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.customFieldMultiEnum {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }
}

.publishInProgress {
  /* Position */
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(var(--zIndexTopbar) + 1);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: 100%;
  width: 100%;

  /* Style */
  background-color: rgba(0, 0, 0, 0.7);
}

.publishInProgressContent {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 18px;
  padding: 0 24px;
  text-align: center;

  /* Position */
  position: relative;
  top: -24px;
}

.publishInProgressIcon {
  stroke: var(--marketplaceColorLight);

  /* Size */
  height: 32px;
  width: 32px;
}

.publishInProgressText {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  color: var(--marketplaceColorLight);
  text-transform: uppercase;
}
