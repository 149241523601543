@import '../../styles/customMediaQueries.css';

.root {
  /* Style */
  background-color: var(--marketplaceColor);

  /* Size */
  padding: 13px 24px;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding: 13px 36px;
  }
}

.logo {
  margin: 0;
  padding: 0;

  & svg {
    /* Size */
    height: 26px;
    width: 186px;

    /* Icon color */
    & path {
      fill: var(--colorWhite);
    }
  }
}

.navigationLinks {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 36px;

  @media (--viewportMedium) {
    gap: 48px;
  }

  @media (--viewportLarge) {
    gap: 72px;
  }
}

.navigationLink {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-weight: var(--fontWeightRegular);
  color: var(--colorWhite);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.legalLink {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}
