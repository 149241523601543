@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  width: 100%;
  height: auto;
  display: flex;

  margin-top: 48px;

  @media (--viewportMedium) {
    /* Position */
    position: absolute;
    bottom: 0;
    left: 0;

    margin-top: 0px;
  }
}

.backButton {
  composes: buttonSecondary from global;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  border: 0;
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  @media (--viewportMedium) {
    min-height: 72px;
    height: 70px;

    /* Size */
    max-width: 275px;
  }

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    outline: 1px solid var(--marketplaceColor);
  }

  &:disabled {
    background-color: var(--colorWhite);
    color: var(--marketplaceColor);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}
