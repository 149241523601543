@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  color: var(--colorFail);

  /* Margin */
  margin-top: 0;
  margin-bottom: 32px;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.fieldsWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  &:nth-child(2) {
    flex-direction: row;
  }

  @media (--viewportMedium) {
    align-items: flex-start;
    flex-direction: row;
  }

  /* Margins */
  composes: formMargins;
  &:last-child {
    margin-bottom: 0;
  }

  & .field {
    flex: 1;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.field {
  composes: formMargins;
}

.instagramField,
.zipCodeField {
  @media (--viewportMedium) {
    width: calc(50% - 12px);
    flex: unset !important;
  }
}

.secondaryAddress{
  margin-top: 20px;
}

.secondaryAddressLabel {
  display: block;
}

.secondaryAddressButton {
  /* Size */
  width: fit-content;

  /* Font */
  font-family: var(--fontFamilySecondary);
  text-transform: capitalize;
}

.deleteSecondaryAddressButton {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 6px;

  margin-top: 24px;
}

.submitButton {
  /* Font */
  color: var(--colorWhite);

  /* Style */
  border: 0;
  background-color: var(--marketplaceColor);
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  @media (--viewportMedium) {
    /* Height */
    min-height: 72px;
    height: 72px;

    /* Layout */
    flex: 1;
  }

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    background-color: var(--marketplaceColorDark);
    outline: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}
