@import '../../styles/customMediaQueries.css';

/* Input wrapper */
.root > div {
  border-color: var(--colorGrey200);

  &:focus-within {
    border-color: var(--marketplaceColor);
    box-shadow: var(--marketplaceColor) 0 0 0 1px;
  }
}

.label {
  display: block;
  margin-bottom: 24px;
}

.tagFontStyles {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  color: var(--marketplaceColor);
  font-size: 14px !important;
  line-height: 18px !important;
}

.tagInputField {
  /* Font */
  composes: tagFontStyles;

  /* Size */
  /* max-width: 108px; */

  /* Style */
  border-bottom: 1px solid var(--marketplaceColor) !important;

  &:autocomplete,
  &:autofill {
    background-color: var(--marketplaceColorLight);
    color: var(--colorWhite);
  }
}

.tagField {
  /* Font */
  composes: tagFontStyles;

  /* Style */
  background: var(--colorFailLight) !important;
}
