@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0;
  gap: 8px;
}

.displayAsRow {
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 2px 0 6px 0;
  }
}