@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 6px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 14px;
  line-height: 18px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}
