@import '../../../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  color: var(--colorFail);

  /* Margin */
  margin-top: 0;
  margin-bottom: 32px;
}

.fieldContainer {
}

.tagFieldContainer {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  @media (--viewportLarge) {
    max-width: 398px;
  }
}

.fieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.field {
  flex: 1;
}

.fieldLabel {
  display: block;

  /* Margin */
  margin-top: 0;
  margin-bottom: 0;
}

.fieldText {
  /* Font */
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColor);

  /* Margin */
  margin-top: 4px;
  margin-bottom: 12px;
}

.checkboxFields {
  margin-top: 24px;

  & ul li label {
    margin-bottom: 4px;
  }

  @media (--viewportMedium) {
    & ul {
      column-gap: 12px;
    }
  }
}

.tagField {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.submitButton {
  /* Font */
  color: var(--colorWhite);

  /* Style */
  border: 0;
  background-color: var(--marketplaceColor);
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  @media (--viewportMedium) {
    /* Height */
    min-height: 72px;
    height: 72px;

    /* Layout */
    flex: 1;
  }

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    background-color: var(--marketplaceColorDark);
    outline: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}
