@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-size: 18px;
  font-family: var(--fontFamilySecondary);

  color: var(--marketplaceColor);
  padding-bottom: 8px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;

    margin-top: 0;
  }
}

.selectedLink {
  border-bottom-color: var(--marketplaceColor);
  color: var(--marketplaceColor);
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}
