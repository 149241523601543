@import '../../styles/customMediaQueries.css';

:root {
  --topbarHeight: 72px;
  --asideWidth: 115px;
  --contentWidth: 1077px;
}

/* Topbar */
.topbar {
  position: relative;
}

/* Content */
.content {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* Margin */
  margin-bottom: 144px;

  @media (--viewportLarge) {
    flex-direction: row;
    align-items: center;
  }
}

/* Sections */
.sections {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  /* Padding */
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0 36px;
  }

  @media (--viewportLarge) {
    padding: 0 36px 0 calc(var(--asideWidth) + 92px);
  }

  /* @media (min-width: 1250px) {
    padding: 0 calc(var(--asideWidth) + 104px);
  } */
}

/* Aside Content */
.asideContent {
  /* Position */
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
  width: 100vw;

  /* Style */
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    width: 100%;
  }

  @media (--viewportLarge) {
    position: unset;
    top: unset;
    background-color: unset;
    width: auto;
  }
}

/* Aside Navigation */
.asideNav {
  @media (--viewportLarge) {
    /* Position */
    position: fixed;
    top: calc(50% - var(--topbarHeight));
    left: 36px;
  }
}

.asideNavList {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;

  /* Scroll */
  overflow-x: auto;

  margin: 0;
  padding: 0;

  @media (--viewportMedium) {
    overflow-x: hidden;
  }

  @media (--viewportLarge) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.asideNavListItem {
  /* Font */
  white-space: nowrap;
  text-transform: uppercase;

  /* Margin */
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 16px;

  /* Style */
  cursor: pointer;
  opacity: 0.4;

  /* Effect */
  transition: var(--transitionStyleButton);

  &:first-child {
    margin-left: 0;
    padding-left: 24px;
  }

  &:last-child {
    padding-right: 24px;
  }

  @media (--viewportMedium) {
    &:first-child {
      padding-left: 36px;
    }

    &:last-child {
      padding-right: 36px;
    }
  }

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 6px;
    margin-left: 0;

    &:hover {
      opacity: 0.6;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.asideNavListActiveItem {
  opacity: 1;

  &:hover {
    opacity: 1;
  }
}

/* SectionContent */
.sectionContent {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 72px 0;

  @media (--viewportMedium) {
    height: 100vh;
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    width: 100%;
    max-width: var(--contentWidth);
  }
}

.sectionContentFirstChild {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 72px 0;

  @media (--viewportMedium) {
    /* height: calc(100vh - 128px); */
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    /* height: calc(100vh - 72px); */
    width: 100%;
    max-width: var(--contentWidth);
  }
}

.sectionContentLastChild {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 72px 0 0;

  @media (--viewportMedium) {
    height: 70vh;
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    height: 80vh;
    width: 100%;
    max-width: var(--contentWidth);
  }
}

.sectionLandingPageContentLastChild {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 72px 0 0;
  height: 100vh;

  @media (--viewportMedium) {
    margin: 0 auto;
    height: 120vh;
  }

  @media (min-width: 1244px) {
    height: 100vh;
    width: 100%;
    max-width: var(--contentWidth);
  }
}

/* SectionAbout */
.sectionAbout {
  composes: sectionContentFirstChild;
}

.aboutTitle {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-size: 32px;
  line-height: 34px;
  color: var(--marketplaceColor);
  text-align: center;
  text-transform: uppercase;

  /* Width */
  max-width: 900px;

  margin-top: 0;
  margin-bottom: 32px;

  @media (--viewportLarge) {
    font-size: 64px;
    line-height: 76px;
  }
}

.aboutText {
  /* Font */
  composes: h4 from global;
  font-size: 24px;
  line-height: 28.8px;
  text-align: center;
  text-transform: none;

  /* Size */
  max-width: 600px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-size: 35px;
    line-height: 37.62px;
  }
}

.aboutSmallText {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-size: 12px;
  text-transform: uppercase;

  margin-top: 48px;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-size: 16px;
    margin-top: 96px;
  }
}

/* SectionProblem */
.sectionProblem {
  composes: sectionContent;
}

.title {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-size: 16px;
  line-height: 16px;
  color: var(--marketplaceColor);
  text-align: center;
  text-transform: uppercase;

  /* Width */
  max-width: 740px;

  margin-top: 0;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    font-size: 35px;
    line-height: 35px;
  }
}

.text {
  /* Font */
  composes: h5 from global;
  font-size: 16px;
  line-height: 19.2px;
  text-align: center;
  text-transform: none;

  max-width: 650px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 28.8px;
  }
}

.sectionProblemTextSpacing {
  /* Layout */
  display: block;
  content: '';
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

/* SectionSolution */
.sectionSolution {
  composes: sectionContent;
}

/* SectionHowItWorks */
.sectionHowItWorks {
  composes: sectionContent;

  /* Reset */
  height: auto;
}

/* SectionHowItWorks */
.sectionLandingPageHowItWorks {
  /* composes: sectionContent; */

  /* Reset */
  height: auto;
  padding: 40px 0px;

  @media (min-width: 1025px) {
    padding: 20px 50px;
  }
}

.howItWorksBorderBox {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Width & Padding */
  width: 100%;
  padding: 0px 48px 96px;

  /* Style */
  /* border: 1px solid var(--marketplaceColor); */

  @media (--viewportMedium) {
    padding: 0px 48px 72px;
  }
}

.howItWorksTitle {
  /* Font */
  font-family: var(--fontFamilySecondary);
  font-size: 32px;
  line-height: 28.8px;
  text-align: center;

  max-width: 900px;
  margin-top: 38px;
  margin-bottom: 0;
  text-transform: uppercase;

  @media (--viewportLarge) {
    font-size: 52px;
    line-height: 52px;
    margin-top: 44px;
  }
}

.howItWorksSubTitle {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-size: 16px;
  text-transform: uppercase;

  margin-top: 0;
  margin-bottom: 0;
}

.howItWorksSmallBoxes {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;

  @media (min-width: 769px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.howItWorksSmallBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.howItWorksSmallBoxWrapper {
  /* Layout */
  display: grid;
  align-items: flex-start;
  padding: 48px 24px;
  min-height: 360px;

  /* Style */
  /* background-color: var(--marketplaceColor); */
  border: 1px solid var(--marketplaceColor);
  flex: 1;

  @media (--viewportLarge) {
    padding: 48px 32px;
    min-height: 364.8px;
  }
}

.howItWorksSmallHeading {
  /* Font */
  font-family: var(--fontFamilySecondary);
  font-size: 24px;
  line-height: 120%;
  /* color: var(--colorWhite); */
  text-align: center;

  /* Margin & Padding */
  margin-top: 0;
  margin-bottom: 24px;
  padding: 0;
}

.howItWorksSmallBoxText {
  /* Font */
  /* color: var(--colorWhite); */
  font-size: 16px;

  text-align: center;

  margin-top: 0;
  margin-bottom: 0;
}

.howItWorksSmallBoxImage {
  height: 200px;
  /* position: absolute; */
  bottom: 0%;
  /*
  @media (min-width:769px) {
    left: -35%;
  }

  @media (min-width:1100px) {
    left: -10%;
  } */
}

/* SectionReviews */
.sectionReviews {
  /* composes: sectionContent; */

  /* Reset */
  display: unset;

  @media (--viewportMedium) {
    height: 100vh;
  }

  @media (--viewportLarge) {
    height: 130vh;
  }
}

.landingSectionReviews {
  /* composes: sectionContent; */
  margin: 50px 0px;
  /* Reset */
  display: unset;

  /* height: 100vh; */

  @media (--viewportMedium) {
    /* height: 130vh; */
  }

  @media (min-width: 1025px) {
    background-color: #f6f6f6;
    /* height: 140vh; */
    /* margin: 50px; */
  }

  @media (min-width: 1244px) {
    /* height: 130vh; */
  }
}

.reviewsTitle {
  /* Font */
  composes: howItWorksSubTitle;
  text-align: center;

  margin-top: 0;
  margin-bottom: 48px;

  @media (--viewportMedium) {
    margin-bottom: 96px;
  }
}

.reviewsCarouselWrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.reviewsCarouselSlider {
  align-items: center;
}

.review {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reviewText {
  /* Font */
  font-family: var(--fontFamilySecondary);
  margin: 32px 0px;
  /* font-size: 24px;
  line-height: 28px;

  margin-top: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    font-size: 36px;
    line-height: 36px;
  }

  @media (--viewportLarge) {
    font-size: 52px;
    line-height: 52px;
  } */

  font-family: 'Berlingske Serif';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 131%; /* 41.92px */
  letter-spacing: -0.64px;
}

.reviewAuthor {
  /* Font */
  /* font-family: var(--fontFamilyPrimary); */
  font-family: 'Inter';
  font-size: 12px;
  text-transform: uppercase;
}

.slideButtons {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 48px;

  @media (--viewportMedium) {
    margin-top: 72px;
  }
}

.slideButton {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  font-size: 12px;
  text-transform: uppercase;
}

/* SectionFaqs */
.sectionFaqs {
  composes: sectionContentLastChild;

  /* Reset */
  align-items: flex-start;
}

/* SectionFaqs */
.sectionLandingPageFaqs {
  /* composes: sectionLandingPageContentLastChild; */

  /* Reset */
  align-items: flex-start;
  margin: 50px 0px;

  @media (min-width: 1025px) {
    margin: 50px;
  }
}

.faqsTitle {
  composes: howItWorksTitle;
  margin-bottom: 50px;

  /* Font */
  text-align: start;
}

.faqs {
  /* Layout */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.faq {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  /* Size */
  width: 100%;
  padding: 24px 0;

  /* Style */
  border-bottom: 1px solid var(--marketplaceColor);
  cursor: pointer;

  &:first-child {
    border-top: 1px solid var(--marketplaceColor);
  }
}

.faqHeadingWrapper {
  /* Layout */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
}

.faqHeading {
  /* Font */
  font-family: var(--fontFamilySecondary);
  font-size: 24px;
  line-height: 28px;
  text-transform: none;

  max-width: 960px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 38px;
    line-height: 38px;
  }
}

.faqText {
  margin-top: 0;
  margin-bottom: 0;
}

.faqIcon {
  flex-shrink: 0;
  margin-top: 4px;

  & path {
    stroke: var(--marketplaceColor);
  }
}

.hideNav {
  display: none;
}

.mainContent {
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1023px) {
    display: block;
  }
}

.mainTitle {
  /* color: #B6030A; */
  font-family: var(--fontFamilySecondary);
  font-size: 54px;
  font-style: normal;
  font-weight: bold;
  line-height: 109%;
  /* 58.86px */
  letter-spacing: -1.08px;
  width: 80%;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 32px;
    letter-spacing: -0.64px;
  }
}

.mainDescription {
  /* color: #B6030A; */
  font-family: var(--fontFamilySecondary);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 109%;
  /* 19.62px */
  letter-spacing: -0.36px;
  padding: 30px 0px;
  width: 80%;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 14px;
    letter-spacing: -0.28px;
  }
}

.mainDescription2 {
  /* color: #B6030A; */
  font-family: var(--fontFamilySecondary);
  font-size: 18px;
  font-style: normal;
  font-weight: bold;

  line-height: 109%;
  /* 19.62px */
  letter-spacing: -0.36px;
  padding-bottom: 97px;
}

.leftContent {
  background-image: url('../../assets/map.png');
  background-size: contain;
  background-position: center;
  /* Centers the image within the element */
  width: 55%;
  margin: 52px 0px 0px;

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0px;
    background-image: none;
  }
}

.iconDesktopContent {
  display: flex;
  text-align: center;
  gap: 10px;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.svgContent {
  padding: 24px 32px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 4px 15.9px 0px rgba(186, 186, 186, 0.25);

  @media (max-width: 600px) {
    padding: 12px 16px;
  }
}

.formContent {
  background: #fff;
  box-shadow: -5px 4px 16.6px 0px rgba(185, 185, 185, 0.25);
  width: 35%;
  margin: 20px;
  width: 100%;
  border: 1px solid var(--marketplaceColor);

  @media (max-width: 1023px) {
    width: 100%;
    margin: 20px;
    /* padding: 20px; */
  }
}

.formContentTitle {
  color: white;
  font-family: var(--fontFamilySecondary);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 109%;
  /* 21.8px */
  letter-spacing: -0.4px;
  text-transform: uppercase;
  background-color: var(--marketplaceColor);
  padding: 20px 32px;
}

.formContentDescription {
  margin-top: 8px;
  color: #7a7a7a;
  font-family: var(--fontFamilySecondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 109%;
  /* 17.44px */
  letter-spacing: -0.32px;
}

.form {
  flex-grow: 1;

  margin: 20px 0px;

  @media (min-width: 1025px) {
    max-width: 800px;
  }
}

.mobileMap {
  @media (max-width: 1023px) {
    background-image: url('../../assets/map.png');
    background-size: contain;
    background-position: center;
    /* Centers the image within the element */
  }
}

.videoResponsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.videoResponsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 98%;
  position: absolute;
  align-items: normal;
  border: 1px solid black;

  /* @media (min-width: 1025px) {
    width: 50%;
    left: auto;
    top: auto;
  } */
}

.videoPosition {
  text-align: center;
  margin: 120px 0px 0px 0px;
}

.imgContent {
  width: 100%;
  /* display: flex;
  justify-content: center; */
  background-color: var(--marketplaceColor);
  /* padding: 24px; */
  position: relative;
  overflow: hidden;
  /* height: 286px; */
  display: flex;
  justify-content: center;
}

.videoTextContent {
  text-align: start;
  @media (min-width: 1025px) {
    max-width: 50%;
  }
}

.videoTextTitle {
  font-family: var(--fontFamilySecondary);
  /* font-family: Almarai; */
  text-transform: uppercase;
  font-size: 32px;
  font-style: normal;
  line-height: 131%; /* 39.3px */
  /* 58.86px */
  letter-spacing: 1.8px;
  /* width: 80%; */

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 24px;
    letter-spacing: -0.64px;
  }
}

.videoContent {
  /* padding: 50px 0px; */
  margin-top: 50px;
  @media (min-width: 1025px) {
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }
}

.videoYoutubeContent {
  width: 100%;
  height: 100vh;
  max-height: 398px;
  margin: 40px 0px;

  @media (min-width: 1025px) {
    margin: 0px;
  }
}

.videoContentWithSVG {
  display: flex;
  gap: 8px;
}

.videoTextDescription {
  margin: 12px 0px;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 131%; /* 23.58px */
  letter-spacing: 1.08px;

  /* @media (max-width: 1023px) {
    font-size: 12px;
    letter-spacing: -0.28px;
  } */
}

.SectionChallenges {
  margin: 80px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SectionCustomTitle {
  font-family: var(--fontFamilySecondary);
  font-size: 32px;
  font-style: normal;
  /* font-weight: bold; */
  line-height: 109%;
  /* 58.86px */
  letter-spacing: -1.08px;
  /* width: 80%; */
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    width: 100%;
    font-size: 32px;
    letter-spacing: -0.64px;
  }
}

.challengesContent {
  padding: 50px 0px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.vl {
  width: 1px;
  align-self: stretch;
  background: var(--marketplaceColor);
}

.challengesText {
  text-align: center;
  font-family: 'Berlingske Serif';
  font-weight: 300;
}

.challengesTextTitle {
  font-weight: 600;
  font-size: 24px;
  margin: 8px;
  font-family: 'Berlingske Serif';
}

/* Style the tab */
.tab {
  overflow: hidden;
  margin: 20px 0px;
  border-bottom: 1px solid grey;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Style the buttons inside the tab */
.tabButton {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  text-transform: uppercase;
}

/* Change background color of buttons on hover */
/* .tab button:hover {
  background-color: #ddd;
} */

/* Create an active/current tablink class */
.tabSelected {
  /* background-color: #ccc !important; */
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  border-bottom: 2px solid var(--marketplaceColor);
  text-transform: uppercase;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.divider {
  width: 100%;
  border-top: 1px solid grey;
}

.howsItWorkButton {
  max-width: 200px;
}

.homePageReviewsCarouselWrapper {
  @media (min-width: 1025px) {
    margin: 50px;
  }
}

.homepagHowItWorksTitle {
  /* Font */
  font-family: var(--fontFamilySecondary);
  font-size: 32px;
  line-height: 28.8px;
  text-align: center;

  max-width: 900px;
  margin-top: 38px;
  margin-bottom: 0;
  text-transform: uppercase;

  @media (--viewportLarge) {
    /* font-size: 52px; */
    line-height: 52px;
    margin-top: 44px;
  }
}

.homePagereviewsTitle {
  /* Font */
  composes: homepagHowItWorksTitle;
  text-align: start;
  margin-bottom: 40px;
}

.homepageFaqsTitle {
  composes: homepagHowItWorksTitle;
  margin-bottom: 50px;

  /* Font */
  text-align: start;
}

.homepageHowItWorksBorderBox {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Width & Padding */
  width: 100%;
  padding: 0px 48px;
}

.homepageHowItWorksSmallBoxes {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding: 48px 0px;

  @media (min-width: 769px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.homepageHireContent {
  padding: 48px 0px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  flex-direction: column;
  align-items: center;

  @media (min-width: 767px) {
    flex-direction: row;
  }
}

.hiringContent {
  text-align: start;
  @media (min-width: 767px) {
    width: 50%;
  }
}

.homePageHireFlex {
  display: flex;
  gap: 12px;
}

.homePageHireImageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.homePageHireTextBold {
  font-weight: bold;
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  color: var(--colorFail);

  /* Margin */
  margin-top: 0;
  margin-bottom: 32px;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.fieldsWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 32px;
}

.fieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  /* &:nth-child(2) {
    flex-direction: row;
  } */

  @media (--viewportMedium) {
    align-items: flex-start;
    flex-direction: row;
  }

  /* Margins */
  composes: formMargins;
  &:last-child {
    margin-bottom: 0;
  }

  & .field {
    flex: 1;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.field {
  composes: formMargins;
}

.submitButton {
  /* Font */
  color: var(--colorWhite);

  /* Style */
  border: 0;
  background-color: var(--marketplaceColor);
  outline: 1px solid var(--marketplaceColor);

  /* Height */
  min-height: 42px;
  height: 42px;

  /* Padding */
  padding: 0;

  @media (--viewportMedium) {
    /* Height */
    min-height: 72px;
    height: 72px;

    /* Layout */
    flex: 1;
  }

  /* Modify button states */
  &:hover {
    border: 0;
    box-shadow: none;
    background-color: var(--marketplaceColorDark);
    outline: 1px solid var(--marketplaceColorDark);
  }

  &:disabled {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);

    &:hover {
      outline: 1px solid var(--marketplaceColor);
    }
  }
}

.radioFields {
  & ul {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    /* Remove default list styles */
    margin: 0;
    padding: 0;

    @media (--viewportMedium) {
      flex-direction: row;
      gap: 24px;
    }

    & li {
      & label {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.checkboxFields {
  & ul {
    /* Layout */
    /* max-width: 580px; */
    margin-top: 12px;

    & li label {
      margin-bottom: 12px;
    }
  }
}

.interestsCheckboxFields ul {
  @media (--viewportMedium) {
    column-count: 3;
  }
}

.fieldContainer {
  composes: formMargins;
  width: 100%;

  @media (--viewportMedium) {
    /* max-width: 500px; */
  }
}

.fieldLabelwithToolTip {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
  margin-left: 12px;
  cursor: pointer;
  bottom: 2px;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  background-color: grey;
  color: white;
  text-align: center;
  padding: 2px 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 5px;
  left: 24px;
  font-size: 12px;
  font-family: 'basis-grotesque-regular-pro';
  text-transform: initial;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.checkboxFields {
  /* margin-top: 24px; */

  & ul li label {
    margin-bottom: 4px;
  }

  @media (--viewportMedium) {
    & ul {
      column-gap: 12px;
    }
  }
}

.tagField {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.customfieldFlex {
  /* Layout */
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.newsLetter {
  margin-top: 20px;
}

.newsLetterfieldText {
  /* Font */
  font-size: 18px;
  line-height: 18px;
  color: var(--marketplaceColor);
  text-transform: initial;

  /* Margin */
  margin-top: 4px;
  margin-bottom: 12px;
}

.passwordContainer {
  margin-top: 20px;
}

.customCheckbox {
  height: 32px;
  margin-top: -1px;
  margin-right: 8px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 28px;
  }
}

.fieldLinkFlex {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
}

.uploadLabel {
  /* Layout */
  display: block;
  width: fit-content;
  margin-top: 32px;
  margin-bottom: 0px;
}

.uploadInput {
  display: none;
}

.uploadZone {
  /* Layout */
  display: flex;
  align-items: center;
  gap: 24px;

  /* Style */
  border: 1px solid var(--marketplaceColor);
  border-radius: 56px;

  /* Size */
  width: fit-content;
  padding: 6px 18px;

  /* Effect */
  cursor: pointer;
}

.uploadZoneIconWrapper {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Style */
  border: 1px solid var(--marketplaceColor);
  border-radius: 50%;

  /* Size */
  height: 20px;
  width: 20px;
}

.uploadZoneIcon {
  stroke: var(--marketplaceColor) !important;

  & line {
    stroke-width: 1;
  }
}

.uploadZoneLabel {
  /* Font */
  font-size: 16px;
  line-height: 100%;
}

.imageFromFileWrapper {
  position: relative;
  width: fit-content;
}

.imageFromFile {
  /* Dimensions */
  width: var(--CreateCompanyPage_avatarSize);
  height: var(--CreateCompanyPage_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--colorGrey100); /* Loading BG color */
  border-radius: calc(var(--CreateCompanyPage_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--CreateCompanyPage_avatarSizeDesktop);
    height: var(--CreateCompanyPage_avatarSizeDesktop);
    border-radius: calc(var(--CreateCompanyPage_avatarSizeDesktop) / 2);
  }
}

.imageFromFileLabelWrapper {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  /* Position */
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 0);

  /* Style */
  background: var(--colorWhite);
  border: 1px dotted var(--marketplaceColor);
  border-radius: 56px;

  /* Size */
  width: fit-content;
  padding: 6px 12px;

  /* Effect */
  cursor: pointer;

  @media (--viewportMedium) {
    padding: 6px 18px;
  }
}

.imageFromFileLabel {
  /* Font */
  font-size: 16px;
  line-height: 100%;
}

.imagesField {
  margin-top: 24px;

  @media (--viewportMedium) {
    max-width: 600px;
    margin-top: 32px;
  }
}

.fieldLabel {
  display: block;
  composes: label from global;

  /* Margin */
  margin-top: 0;
  margin-bottom: 0;
}

.fieldText {
  /* Font */
  font-size: 14px;
  line-height: 18px;
  color: var(--marketplaceColor);
  text-transform: initial;

  /* Margin */
  margin-top: 4px;
  margin-bottom: 12px;
}

.customField {
  flex: 1;

  @media (--viewportMedium) {
    min-width: 300px;
    max-width: 320px;
  }
}

.fieldLabelWithMargin {
  margin-bottom: 24px;
}

.iconBack {
  display: flex;
  gap: 8px;
  @media (min-width: 1025px) {
    /* flex-direction: column; */
  }
}

.backContent {
  padding: 0px 36px;
  @media (--viewportLarge) {
    position: fixed;
    left: 36px;
    top: 88px;
    padding: 0px;
  }
}

.publishInProgress {
  /* Position */
  position: fixed;
  top: 0;
  left: 0;
  z-index: calc(var(--zIndexTopbar) + 1);

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Size */
  height: 100%;
  width: 100%;

  /* Style */
  background-color: rgba(0, 0, 0, 0.7);
}

.publishInProgressContent {
  /* Layout */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 18px;
  padding: 0 24px;
  text-align: center;

  /* Position */
  position: relative;
  top: -24px;
}

.publishInProgressIcon {
  stroke: var(--marketplaceColorLight);

  /* Size */
  height: 32px;
  width: 32px;
}

.publishInProgressText {
  /* Font */
  font-family: var(--fontFamilyPrimary);
  color: var(--marketplaceColorLight);
  text-transform: uppercase;
}
