@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 0;

  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  width: 100vw;
  overflow: auto;

  @media (--viewportMedium) {
    width: calc(100vw - 48px);
  }
  @media (--viewportLarge) {
    width: unset;
  }
}
:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--colorWhite);
  border: 1px solid var(--marketplaceColor);
}

:global(.fullscreen) {
  background-color: var(--colorWhite) !important;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.root {
}

.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper {
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  width: 100%;
  height: 100%;

  /* Effect */
  object-fit: cover;
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 88px;
  max-height: 88px;
  border-radius: var(--borderRadiusMedium);
}

.navLeft,
.navRight {
  position: absolute;
  width: 24px; /* Size of navArrowWrapper */
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  & .arrowIcon {
    stroke: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    width: 32px; /* Size of navArrowWrapper */
  }

  /* Hover */
  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: var(--marketplaceColor);

      & .arrowIcon {
        stroke: var(--colorWhite);
      }
    }
  }
}

.navLeft {
  left: 0;
}

.navRight {
  right: 0;
}

.navArrowWrapper {
  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  height: 42px;
  width: 24px;

  background-color: var(--colorWhite);
  outline: 1px solid var(--marketplaceColor);

  @media (--viewportMedium) {
    height: 52px;
    width: 32px;

    background-color: rgba(255, 255, 255, 0.8);
  }
}

.navArrowWrapper .arrowIcon {
  height: 8px;

  @media (--viewportMedium) {
    height: 10px;
  }
}
